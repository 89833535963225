<template>
  <div>
    <div class="mainContent">
      <a-form-model :layout="layout" style="width:100%;" ref="ruleForm" :model="form">
        <a-col :sm="24" :md="12" :lg="8" :xl="8">
          <a-form-model-item label="用户名称" colon :labelCol='labelcol' :wrapperCol="wrappercol" prop="username">
            <a-input v-model="form.username" placeholder="请输入用户名称..." />
          </a-form-model-item>
        </a-col>

        <a-col :sm="24" :md="12" :lg="8" :xl="8">
          <a-form-model-item label="行为名称" colon :labelCol='labelcol' :wrapperCol="wrappercol" prop="action_name">
            <a-input v-model="form.action_name" placeholder="请输入行为名称..." />
          </a-form-model-item>
        </a-col>

        <a-col :sm="24" :md="12" :lg="8" :xl="8">
          <a-form-model-item label="开始时间" colon :labelCol='labelcol' :wrapperCol="wrappercol" prop="start_time">
            <a-date-picker
              format="YYYY-MM-DD HH:mm:ss"
              show-time
              v-model="form.start_time"
              @change="onChangeStartTime"
            />

          </a-form-model-item>
        </a-col>

        <a-col :sm="24" :md="12" :lg="8" :xl="8">
          <a-form-model-item label="结束时间" colon :labelCol='labelcol' :wrapperCol="wrappercol" prop="end_time">
            <a-date-picker
              format="YYYY-MM-DD HH:mm:ss"
              show-time
              v-model="form.end_time"
              @change="onChangeEndTime"
            />
          </a-form-model-item>
        </a-col> 
       

        <a-col :sm="24" :md="12" :lg="8" :xl="6" class="lyq_flex_grow">
          <a-form-model-item style="text-align:right;">

            <a-button type="primary" @click="search">
              搜索
            </a-button>
            <a-button @click="reset('ruleForm')">重置</a-button>

          </a-form-model-item>
        </a-col>
      </a-form-model>
    </div>


    <div class="mainContent" >

      <div class="tableHeader">
        <div class="title">查询表格</div>
        <div class="operate">

          <a-tooltip placement="top">
            <template slot="title">
              <span>下载</span>
            </template>
            <a class="ant-dropdown-link" style="display:block;margin-right:10px;" @click="start" :disabled="!selectedRowKeys.length > 0">
              <a-icon type="vertical-align-bottom" />
            </a>
          </a-tooltip>

          <a-tooltip placement="top">
            <template slot="title">
              <span>表格大小</span>
            </template>
            <a-dropdown :trigger="['click']" style="margin-right:10px;">
              <a class="ant-dropdown-link" style="display:block">
                <a-icon type="column-height" />
              </a>
              <a-menu slot="overlay">
                <a-menu-item>
                  <a @click="tableSize='default'">默认</a>
                </a-menu-item>
                <a-menu-item>
                  <a @click="tableSize='middle'">中等</a>
                </a-menu-item>
                <a-menu-item>
                  <a @click="tableSize='small'">紧凑</a>
                </a-menu-item>
              </a-menu>
            </a-dropdown>
          </a-tooltip>

        </div>
      </div>

      <a-table
        :size="tableSize"
        :scroll="{ x: true }"
        :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
        :columns="columns"
        :row-key="record => record.create_time"
        :data-source="data"
        :pagination="pagination"
        :loading="loading"
        bordered
        @change="handleTableChange"
      >
        <!-- 操作单元格 -->
        <template slot="操作" slot-scope="data">

              
                <a-button size="small" type="primary"  @click="onSee(data)">查看详情</a-button>
      
        </template>
      </a-table>
    </div>
    <!-- 查看详情 -->
    <a-modal v-model="seeVisible" title="商户详情" @ok="OK" width="800px" :maskClosable="false">
      <a-descriptions
      title=""
      bordered
      :column="{ xxl: 1, xl: 1, lg: 1, md: 1, sm: 1, xs: 1 }"
    >
      <a-descriptions-item label="用户名">
        {{onSeeData.username}}
      </a-descriptions-item>
      <a-descriptions-item label="IP">
        {{onSeeData.ip}}
      </a-descriptions-item>
      <a-descriptions-item label="操作时间">
        {{onSeeData.create_time}}
      </a-descriptions-item>
      <a-descriptions-item label="行为名称">
        {{onSeeData.action_name}}
      </a-descriptions-item>

    </a-descriptions>

    </a-modal>

  </div>
</template>
<script>
import {getError_list,getAction_list} from "@/axios/log.js"

import {getCookie} from '@/static/js/cookie';
import {myMixin} from "@/static/js/mixin.js"
import moment from 'moment';
const columns = [//列描述

  {
    align:'center',
    title: '用户名',
    dataIndex: 'username',
  },
  {
    align:'center',
    title: '行为名称',
    dataIndex: 'action_name',
  },
  {
    align:'center',
    title: '客服IP',
    dataIndex: 'ip',
  },
  {
    align:'center',
    title: '创建时间',
    dataIndex: 'create_time',
  },

  {
    align:'center',
    title: '操作',
    // dataIndex: 'status',
    scopedSlots: { customRender: '操作', },
  },

];
export default {
  filters: {

  },
  mixins: [myMixin],
  data() {

    return {


      form: {
        page_size: 10,
        page:1,
        start_time:'',
        end_time:'',
        username:'',
        action_name:'',
      },

      columns,//列描述

      confirmLoading:false,//编辑模态框
      editModalVisible:false,
 
  

      title:'错误详情',
      seeVisible:false,
      onSeeData:{
        action_name:'',
        username:'',
        ip:'',
        create_time:'',
      },
    }

  },
  mounted() {
    let that=this
    this.form.start_time=moment(moment().format("YYYY-MM-DD 00:00:00"))
    this.form.end_time=moment(moment().format("YYYY-MM-DD 23:59:59"))
    this.fetch();
  },
  methods:{
      reset(formName){
        this.data = [];
        this.$refs[formName].resetFields();
        this.pagination.current =1;
        this.form.page=1
        this.form.start_time=moment(moment().format("YYYY-MM-DD 00:00:00"))
        this.form.end_time=moment(moment().format("YYYY-MM-DD 23:59:59"))
        this.fetch();
      },
      onChangeStartTime(date, dateString){
        this.form.start_time=dateString
      },
      onChangeEndTime(date, dateString){
        this.form.end_time=dateString
      },

      async fetch (params = {}) {
        this.loading = true;
        let start_time=moment(this.form.start_time).format("YYYY-MM-DD HH:mm:ss")
        let end_time=moment(this.form.end_time).format("YYYY-MM-DD HH:mm:ss")
        let data=await getAction_list({...params,...this.form,start_time:start_time,end_time:end_time})

        this.loading = false;
        const pagination = { ...this.pagination };
        pagination.total = data.data.total;

        this.data = data.data.list;
        this.pagination = pagination;
        console.log(data,this.pagination,pagination,'data')
      },

      onSee(data){
        this.seeVisible=true
        this.onSeeData={...data}
      },
      OK(data){
        this.onSeeData={}
        this.seeVisible=false
      },
    
    }
};
</script>
<style lang="scss" scoped>
@import "@/static/css/index.scss";
.ant-table td { white-space: nowrap; }

::v-deep .ant-table-bordered .ant-table-thead > tr > th{    word-break: inherit;}
::v-deep .ant-modal-body {

    max-height: 600px;
    overflow-x: auto;
}
</style>
